import { useCallback, useContext, useEffect, useRef, useState } from "react";
import api from "../../api";
import config from "../../config";
import { UserContext } from "../../context/UserContext";
import { useNavigate } from "react-router";
import CusDetailComp from "../Home/Component/CusDetailComp";
import Cookies from 'universal-cookie';
import { useReactToPrint } from 'react-to-print';
import QRCode from 'qrcode'

export function usePaymentNonCash(){
    const cookies = new Cookies();
    const [isLoading, setIsLoading] = useState(false);
    
    const [alertValue, setAlertValue] = useState({
        show: false,
        color: 'danger',
        text: '',
    });
    const [isOnline, setIsOnline] = useState(false);
    const [token, setToken] = useState('');
    const [customerDetail, setCustomerDetail] = useState(null);
    const myState = useContext(UserContext);
    const [listVa, setListVa] = useState([]);
    const [developmentMode, setDevelopmentMode] = useState(false);
    const [rightState, rightDispatch] = myState.rightState;
    const [inputHp, setInputHp] = myState.inputHp;
    const [usingVa, setUsingVa] = useState(false);
    const [messages, setMessages] = useState([]);
    const navigate = useNavigate();
    const [paymentId, setPaymentId] = useState(null);
    const [view, setView] = useState('standby');
    const [companyCode, setCompanyCode] = useState(null);
    const [vaNumber, setVaNumber] = useState(null);
    const [expTime, setExpTime] = useState(null);
    const [qrImage, setQrImage] = useState('');
    const [paymentUrl, setPaymentUrl] = useState('');
    const componentRef = useRef();
    const ws = useRef(null);
    
    const [paymentSuccessResume, setPaymentSuccessResume] = useState({
        nama: 'Pitoyo',
        nomor_invoice: 'limalima',
        total: 10000
    });
    const [waitingToReconnect, setWaitingToReconnect] = useState(null);
    const [selectedVa, setSelectedVa] = useState({
        "id" : 1,
        "nama" : 'BCA',
        "logo" : 'http://127.0.0.1:8000/media/icon/Bca.png',
        "script_kode" : 'bni',
        "is_active" : true
    });
    
    const fetchData = useCallback(async(formInput) => {        
        try{
            let newForm = new FormData();
            let tmp = {
                'noinvoice': formInput
            }
            newForm.append('formData', JSON.stringify(tmp))
            const res = await api.post(`${config.endPoint.invoiceInfo}`, newForm).then(res => res.data);
            console.log(res)
            if (res){
                if (res.results.json_data){
                    if (res.results.payment_type === 1){
                        setView('qris');
                        setUsingVa(false);

                        const urlRegex = /^(https?:\/\/[^\s$.?#].[^\s]*)$/gm;

                        if (!urlRegex.test(res.results.qr_url)) {
                            QRCode.toDataURL(res.results.qr_url, function (err, url) {
                                console.log(url)
                                setQrImage(url);
                                setPaymentUrl(url);
                            });
                        } else {
                            setQrImage(res.results.qr_url);
                            setPaymentUrl(res.results.qr_url);
                        }


                    }else{
                        setUsingVa(true);
                        setView('va_selected');
                        setVaNumber(res.results.no_va)
                        setSelectedVa(selectedVa => ({...selectedVa,                         
                            script_kode : res.results.bank
                        }))
                        setCompanyCode(res.results.company_code)
                    }
                }else{
                    setPaymentId(res.results.id);
                    if (parseInt(res.results.total) >= parseInt(res.max_to_va)){
                        setUsingVa(true);
                        setView('list_va')
                    }else{
                        setUsingVa(false);
                        setView('qris')

                        const urlRegex = /^(https?:\/\/[^\s$.?#].[^\s]*)$/gm;

                        if (!urlRegex.test(res.results.qr_url)) {
                            QRCode.toDataURL(res.results.qr_url, function (err, url) {
                                console.log(url)
                                setQrImage(url);
                                setPaymentUrl(url);
                            });
                        } else {
                            setQrImage(res.results.qr_url);
                            setPaymentUrl(res.results.qr_url);
                        }
                    }
                }
                let tmp = {...res.results}
                tmp['logo'] = res.logo;
                tmp['ahass_name'] = res.ahass_name
                // setVaNumber(res.result.no_va)
                setCustomerDetail(tmp);                 
                rightDispatch({
                    type: 'OTHERS',
                    content : <CusDetailComp
                        customerDetail={res.results}
                    />
                })
                
                
            }
            setIsLoading(false);
        }catch(error){

        }
        // eslint-disable-next-line
    },[rightDispatch])

    const handleCloseAlert = () => {
        setAlertValue(alertValue => ({ ...alertValue, show: false }))
    }
    

    useEffect(() => {
        if (rightState.type !== 'OTHERS'){            
            fetchData(sessionStorage.getItem('inputHp'))        
        }
            
        // eslint-disable-next-line
    },[fetchData])

    const fetchListVa = useCallback(async() => {
        setIsLoading(true);
        try{
            let params = {
                is_active: true
            }
            const res = await api.get(`${config.endPoint.masterVa}`, {params: params}).then(res => res.data);
            if (res){
                setListVa(res.results);
            }
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            let status = error && error.response && error.response.status ? error.response.status : '';
            if (status && status === 401) {
                return window.location.href = "/login"
            } else {
                setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Unable to fetch Data', color: 'error' }));
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }));
                }, config.timeOutValue)
            }
        }
    },[setIsLoading, setAlertValue])

    useEffect(() => {
        fetchListVa();
    },[fetchListVa])


    const handleSubmitByVa = async(post) => {
        setIsLoading(true);
        setSelectedVa(post);        
        try{
            let tmpForm = {}
            tmpForm['bank_id'] = post.id;
            tmpForm['bank'] = post.script_kode
            tmpForm['results'] = customerDetail
            let newForm = new FormData();

            newForm.append('formData', JSON.stringify(tmpForm))
            const res = await api.post(`${config.endPoint.createVa}`, newForm).then(res => res.data);            
            if (res && res.result_record){
                setVaNumber(res.result_record.no_va);
                setCompanyCode(res.result_record.company_code);
                setView('va_selected');
            }            
            setIsLoading(false);
        }catch(error){
            let msg = 'Kami sedang mengalami gangguan';
            let { response } = error;
            if (response && response.data && response.data.message) {
                msg = response.data.message
            }
            setIsLoading(false);
            setAlertValue(alertValue => ({ ...alertValue, show: true, text: msg, color: 'danger' }));
            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: false }));
            }, config.timeOutValue)
        }
    }

    const handleKembali = () => {
        // setView('standby');   
        setInputHp('')
        navigate('/')
    }

    useEffect(() => {
        const myCookies = cookies.get(config.cookiesName);
        if (myCookies && myCookies.token) {
            setToken(myCookies.token);
            // openWs(myCookies.token)
        }
        if (myCookies && myCookies.development) {
            setDevelopmentMode(true);
        }
        // eslint-disable-next-line
    }, [])

    const handleCancelPayment = async() => {
        setIsLoading(true);        
        try {
            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(customerDetail))

            const res = await api.post(`${config.endPoint.cancelPayment}`, newForm).then(res => res.data);
            if (res){
                handleKembali();
            }            
        } catch (error) {
            let msg = 'Kami sedang mengalami gangguan';
            let { response } = error;
            if (response && response.data && response.data.message) {
                msg = response.data.message
            }
            setIsLoading(false);
            setAlertValue(alertValue => ({ ...alertValue, show: true, text: msg, color: 'danger' }));
            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: false }));
            }, config.timeOutValue)
        }
    }

    
    // ============================ socket ==============================
    const addMessage = useCallback((myMsg) =>  {
        setMessages(messages => [...messages, myMsg]); 

        try {
            let message = JSON.parse(myMsg);                        
            if (message && message.text && customerDetail && message.text.id === customerDetail.id) {
                // console.log('message bawah is >>>', message)
                if (message.text.type === 'payment_notification'){
                    setPaymentSuccessResume(message.text);
                    setCustomerDetail(customerDetail => ({...customerDetail, is_paid : true}))
                    setView('is_paid')                 
                    setTimeout(() => {
                        printThePage();
                    }, 2000)   
                    setTimeout(() => {
                        setInputHp('');
                        navigate('/')
                    }, 6000)
                    // let timer = setTimeout(() => {
                    //     setFormInput('');
                    //     setShowQr(false);
                    //     setIsPaid(false);
                    //     setShowVideo(true);
                    //     setShowInvoice(false);
                    //     handleCancel();                        
                    // }, 6000)
    
                    
                }                
                
            }
            
        } catch (e) {
            console.log('error coy >>>>', e);
            return false;
        }
        return true;     
        // eslint-disable-next-line   
    }, [customerDetail]);

    // const setReceiveMessage

    useEffect(() => {
        if (token){        
            if (waitingToReconnect) {
                return;
            }

            // Only set up the websocket once
            if (!ws.current) {
                // const client = new W3CWebSocket(config.apiBaseUrl.wsServer + token + '/');
                const client = new WebSocket(config.apiBaseUrl.wsServer + token + '/');
                ws.current = client;

                window.client = client;

                client.onerror = (e) => console.error(e);

                client.onopen = () => {
                    setIsOnline(true);
                    console.log('ws opened');
                    client.send(JSON.stringify({message : 'ping'}));
                };

                client.onclose = () => {

                    if (ws.current) {
                        // Connection failed
                        console.log('ws closed by server');
                    } else {
                        // Cleanup initiated from app side, can return here, to not attempt a reconnect
                        console.log('ws closed by app component unmount');
                        return;
                    }

                    if (waitingToReconnect) {
                        return;
                    };

                    // Parse event code and log
                    setIsOnline(false);
                    console.log('ws closed');

                    // Setting this will trigger a re-run of the effect,
                    // cleaning up the current websocket, but not setting
                    // up a new one right away
                    setWaitingToReconnect(true);

                    // This will trigger another re-run, and because it is false,
                    // the socket will be set up again
                    setTimeout(() => setWaitingToReconnect(null), 5000);
                };

                client.onmessage = message => {
                    addMessage(message.data);
                };


                return () => {

                    console.log('Cleanup');
                    // Dereference, so it will set up next time
                    ws.current = null;

                    client.close();
                }
            }
        }
        // eslint-disable-next-line
    }, [waitingToReconnect, token, addMessage]);

    const printThePage = useReactToPrint({
        content: () => componentRef.current,
    });

    useEffect(() => {
        if (!ws.current) return;

        ws.current.onmessage = e => {
            const message = JSON.parse(e.data);
            if (message && message.text && customerDetail && message.text.id === customerDetail.id) {
                if (message.text.type === 'payment_notification'){
                    setPaymentSuccessResume(message.text);
                    setView('is_paid')    
                    setCustomerDetail(customerDetail => ({...customerDetail, is_paid : true}))
                    setTimeout(() => {
                        printThePage();
                    }, 2000) 
                    setTimeout(() => {
                        setInputHp('');
                        navigate('/');
                    }, 6000)
                }                               
            }
        };
        // eslint-disable-next-line
    }, [ws, customerDetail]);
    
    
    return {
        isLoading, customerDetail, setIsLoading, paymentId, view, listVa,
        handleSubmitByVa, vaNumber, handleKembali, selectedVa, companyCode,
        token, developmentMode, paymentUrl, qrImage,
        handleCancelPayment, paymentSuccessResume, printThePage,
        usingVa, alertValue, isOnline, componentRef, expTime, setExpTime,
        handleCloseAlert, messages, setInputHp, inputHp
    }
}